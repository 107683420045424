<template>
  <v-container
    fluid
    class="container-bg rounded elevation-3 pa-6">
    <v-row>
      <v-col cols="10">
        <h2>Stock Count Detail</h2>
      </v-col>
      <v-col
        cols="2"
        class="d-flex justify-end">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="primary"
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in menus"
              :key="index"
              link
              :disabled="item.disabled"
              @click="item.action">
              <v-list-item-title>
                <v-icon
                  :color="item.iconColor"
                  class="mr-2">
                  {{ item.icon }}
                </v-icon> {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col
        cols="12">
        <table
          v-if="stockCount"
          class="table-detail">
          <tbody>
            <tr>
              <td class="text-bold">
                สถานะ :
              </td>
              <td :class="stockCountStateColor">
                {{ stockCountStateText }}
              </td>
            </tr>
            <tr>
              <td class="text-bold">
                ID :
              </td>
              <td>{{ stockCount.code }}</td>
            </tr>
            <tr>
              <td class="text-bold">
                จำนวน :
              </td>
              <td>{{ stockCount.stockCount }} / {{ stockCount.stockBc }}</td>
            </tr>
            <tr>
              <td class="text-bold">
                Warehouses :
              </td>
              <td>{{ stockWarehouse }}</td>
            </tr>
            <tr>
              <td class="text-bold">
                Completed By :
              </td>
              <td>{{ completedTimestamp.actionBy || '-' }}</td>
            </tr>
            <tr>
              <td class="text-bold">
                Completed Date :
              </td>
              <td>{{ completedTimestamp.actionAt | dateTimeUserFormat() }}</td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="5"
        offset-md="7"
        class="d-flex justify-end">
        <WarehouseSelect
          v-model="query.warehouseIds"
          :items="filteredWarehouse"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          label="Warehouses"
          chips
          small-chips
          deletable-chips
          multiple
          outlined
          class="mr-2" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          class="elevation-1"
          :headers="stockCountItemsHeaders"
          :items="stockCountItems"
          :loading="loading"
          hide-default-footer
        >
          <template #[`item.state`]="{ item }">
            <span :class="`${stockCountLogsStates[item.state].color}--text`">
              {{ stockCountLogsStates[item.state].name }}
            </span>
          </template>
          <template #[`item.createdAt`]="{ item }">
            {{ item.createdAt | dateTimeFormat() }}
          </template>
          <template #[`item.note`]="{ item }">
            {{ item.note || '-' }}
          </template>
          <template #[`item.warehouse`]="{ item }">
            {{ item.warehouse.join(",  ") }}
          </template>
          <template #[`item.actions`]="{ item }">
            <div class="d-flex align-center justify-center">
              <v-menu offset-y>
                <template #activator="{ attrs, on }">
                  <v-btn
                    color="primary"
                    small
                    v-bind="attrs"
                    v-on="on">
                    action
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    link
                    @click="openDetail(item)">
                    <v-list-item-title>
                      <v-icon
                        color="#26C6DA"
                        class="mr-2">
                        mdi-magnify
                      </v-icon> ดูข้อมูล
                    </v-list-item-title>
                  </v-list-item>
                  <template v-if="!isDisable">
                    <v-list-item
                      v-for="(state, index) in stockCountLogsStates[item.state].isCan"
                      :key="index"
                      link
                      @click="openModalConfirmUpdateState(item.id, item.state, state)">
                      <v-list-item-title>
                        <v-icon
                          :color="stockCountLogsStates[state].color"
                          class="mr-2">
                          {{ stockCountLogsStates[state].icon }}
                        </v-icon> {{ stockCountLogsStates[state].name }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list-item
                    link
                    @click="exportCsv(item.items, item.code)">
                    <v-list-item-title>
                      <v-icon
                        color="#1B5E20"
                        class="mr-2">
                        mdi-file-export-outline
                      </v-icon> Export CSV
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NoPicture from '@/assets/image/no_picture_available.png'
import NewStockCountProvider from '@/resources/NewStockCountProvider'
import NewStockCountDetailProvider from '@/resources/NewStockCountDetailProvider'
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import XLSX from 'xlsx'
import dayjs from 'dayjs'

const newStockCountService = new NewStockCountProvider()
const newStockCountDetailService = new NewStockCountDetailProvider()

export default {
  components: {
    WarehouseSelect
  },
  data () {
    return {
      stockCount: null,
      stockCountItems: [],
      loading: false,
      stockCountItemsHeaders: [
        {
          text: 'Code',
          align: 'start',
          sortable: false,
          value: 'code'
        },
        {
          text: 'Create Time',
          align: 'center',
          sortable: true,
          value: 'createdAt'
        },
        {
          text: 'Status',
          align: 'center',
          sortable: false,
          value: 'state'
        },
        {
          text: 'Location',
          align: 'center',
          sortable: false,
          value: 'note'
        },
        {
          text: 'Warehouse',
          align: 'center',
          sortable: false,
          value: 'warehouse.name'
        },
        {
          text: 'Count Product',
          align: 'right',
          sortable: true,
          value: 'stockCount'
        },
        // {
        //   text: 'Count By',
        //   align: 'center',
        //   sortable: true,
        //   value: 'createdBy.email'
        // },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false }
      ],
      stockCountState: {
        pending: {
          name: 'รอนับ',
          value: 'pending',
          color: 'red'
        },
        processing: {
          name: 'กำลังนับ',
          value: 'processing',
          color: 'indigo'
        },
        inspecting: {
          name: 'รอตรวจสอบ',
          value: 'inspecting',
          color: 'orange'
        },
        completed: {
          name: 'สำเร็จ',
          value: 'completed',
          color: 'green'
        }
      },
      stockCountLogsStates: {
        approved: {
          name: 'ยืนยัน',
          value: 'approved',
          color: 'indigo',
          isCan: ['canceled'],
          icon: 'mdi-check-decagram-outline'
        },
        pending: {
          name: 'รอยืนยัน',
          value: 'pending',
          color: 'orange',
          isCan: ['delete', 'approved'],
          icon: 'mdi-clock-alert-outline'
        },
        canceled: {
          name: 'ยกเลิก',
          value: 'canceled',
          color: 'gray',
          isCan: [],
          icon: 'mdi-cancel'
        },
        delete: {
          name: 'ลบ',
          value: 'delete',
          color: 'red',
          isCan: [],
          icon: 'mdi-delete-forever-outline'
        },
        completed: {
          name: 'เสร็จสิ้น',
          value: 'completed',
          color: 'green',
          isCan: [],
          icon: 'mdi-check-decagram-outline'
        }
      },
      query: {
        status: '',
        page: 1,
        limit: 10,
        search: '',
        warehouseIds: [-1],
        stockCount: this.$route.params.stockCountId
      },
      pageCount: 0,
      importDialog: false,
      noPicture: NoPicture
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse',
      modal: 'Components/modal'
    }),
    stockCountStateColor () {
      return this.stockCount.state ? `${this.stockCountState[this.stockCount.state].color}--text` : ''
    },
    stockCountStateText () {
      return this.stockCount.state ? this.stockCountState[this.stockCount.state].name : ''
    },
    stockWarehouse () {
      return this.stockCount ? this.stockCount.warehouses.map((v) => v.name).join(', ') : '-'
    },
    filteredWarehouse () {
      const stockCountWarehouses = this.stockCount?.warehouses || []
      const stockCountWarehouseIds = stockCountWarehouses.map((v) => v.id)

      return [
        {
          name: 'All',
          id: -1,
          code: 'All'
        },
        ...this.mapWarehouse.filter((v) => stockCountWarehouseIds.includes(v.id))
      ]
    },
    isDisable () {
      return this.stockCount && (this.stockCount.state === 'completed' || this.stockCount.state === 'canceled')
    },
    menus () {
      if (this.isDisable) {
        return [
          {
            title: 'Export ใบสั่งนับ',
            icon: 'mdi-file-export-outline',
            iconColor: '#1B5E20',
            action: () => this.exportCsv(this.stockCount.items, this.stockCount.code)
          }
        ]
      }

      const disabled = this.stockCountItems.some((v) => v.state === 'pending')

      return [
        {
          title: 'นับของ',
          icon: 'mdi-counter',
          iconColor: 'indigo',
          action: () => {
            this.$router.push(
              {
                name: 'StockCountCheck',
                params: this.$route.params
              }
            )
          }
        },
        {
          title: 'ยืนยันการนับของ',
          icon: 'mdi-check-decagram-outline',
          iconColor: 'blue',
          action: () => {
            this.openModalApproveStockCount()
          },
          disabled
        },
        {
          title: 'Export ใบสั่งนับ',
          icon: 'mdi-file-export-outline',
          iconColor: '#1B5E20',
          action: () => this.exportCsv(this.stockCount.items, this.stockCount.code)
        }
      ]
    },
    completedTimestamp () {
      const timestamp = this.stockCount?.stateHistories?.find((v) => v.state === 'completed')

      return {
        actionBy: timestamp?.actionBy?.email || '-',
        actionAt: timestamp?.actionAt || ''
      }
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getStockCountLogsList()
      },
      deep: true
    },
    'query.state': {
      handler () {
        this.getStockCountLogsList()
      },
      deep: true
    },
    'query.search': {
      handler () {
        clearTimeout(this.delayVendorSearch)
        this.delayVendorSearch = setTimeout(() => {
          this.getStockCountLogsList()
        }, 300)
      },
      deep: true
    },
    'query.warehouseIds': {
      handler (val) {
        this.handleWarehouseSelect(val)
      },
      deep: true
    }
  },
  mounted () {
    this.getStockCountLogsList()
    this.getStockCount()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading',
      setModal: 'Components/setModal'
    }),
    handleWarehouseSelect (val) {
      if (val.length > 1 && val[0] === -1) {
        this.query.warehouseIds = val.filter((v) => v !== -1)
      } else if (val.includes(-1)) {
        this.query.warehouseIds = [-1]
      }

      this.getStockCountLogsList()
    },
    async getStockCountLogsList () {
      try {
        this.setLoading({ active: true })

        const { data } = await newStockCountDetailService.getAll({
          ...this.query,
          warehouseIds: this.query.warehouseIds.includes(-1)
            ? this.filteredWarehouse.map((v) => v.id).filter((v) => v !== -1)
            : this.query.warehouseIds.filter((v) => v !== -1)
        })

        this.stockCountItems = data.map((logList) => {
          const stockCount = logList.items.reduce((acc, current) => acc + current.quantity, 0)

          return { ...logList, stockCount }
        })
      } catch (error) {
        console.error('getStockCountLogsList', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async getStockCount () {
      try {
        this.setLoading({ active: true })

        const { data } = await newStockCountService.getById(this.$route.params.stockCountId)
        const stockSummary = data.items.reduce((previousValue, currentValue) => {
          let acc = { ...previousValue }
          if (!acc?.stockCount) {
            acc = {
              stockCount: 0,
              stockCountValue: 0,
              stockBc: 0,
              stockBcValue: 0
            }
          }
          acc.stockCount += currentValue.quantity
          acc.stockCountValue += currentValue.quantity ? currentValue.quantity * currentValue.price : 0

          acc.stockBc += currentValue.physicalQuantity
          acc.stockBcValue += currentValue.physicalQuantity ? currentValue.physicalQuantity * currentValue.price : 0

          return acc
        }, {})

        this.stockCount = { ...data, ...stockSummary }
      } catch (error) {
        console.error('getStockCount', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    openModalConfirmUpdateState (id, preViousStatus, newStatus) {
      this.setModal({
        value: true,
        title: 'ยืนยันการเปลี่ยนสถานะ',
        message: `ต้องการเปลี่ยนสถานะของใบนับสินค้าจาก 
          "${this.stockCountLogsStates[preViousStatus].name}" เป็น "${this.stockCountLogsStates[newStatus].name}" หรือไม่`,
        confirmText: 'ยืนยัน',
        confirmType: 'success',
        cancelType: '',
        cancelText: 'ยกเลิก',
        onConfirm: () => this.changeDetailStatusState(id, newStatus)
      })
    },
    openModalApproveStockCount (id, newStatus) {
      this.setModal({
        value: true,
        title: 'ยืนยันการนับของเสร็จสิ้น',
        message: 'ต้องการยืนยันการนับของเสร็จสิ้นหรือไม่',
        confirmText: 'ยืนยัน',
        confirmType: 'success',
        cancelType: '',
        cancelText: 'ยกเลิก',
        onConfirm: () => this.changeStockCountStatusState(id, newStatus)
      })
    },
    openDetail (item) {
      this.$router.push({
        name: 'StockCountCheckDetail',
        params: {
          stockCountId: this.stockCount.id,
          detailId: item.id
        },
        query: {
          warehouse: item.warehouse.id
        }
      })
    },
    exportCsv (items, name) {
      const dataForconvert = items.map((item) => ({
        'brand': item.brand,
        'code': item.code,
        'model': item.model,
        'name': item.name,
        'size': item.size,
        'color': item.color,
        'physical quantity': item.physicalQuantity,
        'quantity': item.quantity,
        'price': item.price
      }))
      const worksheet = XLSX.utils.json_to_sheet(dataForconvert)
      const csvData = XLSX.utils.sheet_to_csv(worksheet)
      const fileName = `${name}-${dayjs().format('DD-MM-YYYY')}`
      this.downloadCsv(csvData, fileName)
    },
    downloadCsv (csvData, name) {
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', `${name}.csv`)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    async changeDetailStatusState (id, state) {
      try {
        this.setLoading({ active: true })

        if (state === 'delete') {
          await newStockCountDetailService.deleteItem(id)
        } else {
          await newStockCountDetailService.updateStatusState(id, state)
        }

        this.getStockCountLogsList()
        this.getStockCount()
      } catch (error) {
        console.error('changeDetailStatusState', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async changeStockCountStatusState () {
      try {
        this.setLoading({ active: true })

        await newStockCountService.updateStatusState(this.stockCount.id, 'completed')
        this.getStockCountLogsList()
        this.getStockCount()
      } catch (error) {
        console.error('changeStockCountStatusState', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
.table-detail {
  width: 100%;
}
.table-detail tr {
  line-height: 35px;
}
</style>
